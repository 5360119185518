* {
	transition: none !important;
}
#adminbar {
	display: none !important;
}

.agent-quote-edit-buttons {
	form[data-order-status="accepted"],
	form[data-order-status="declined"],
	form[data-order-status="declinedexpired"] {
		display: none !important;
	}
}
